import logo from './logo.svg';
import './App.css';

import { LogoGithub, LogoLinkedin, Mail, LinkOutline} from 'react-ionicons';

import profileImage from './input_files/pfp_crop.jpg';

function App() {
  return (
    <div className="App">
      <main className="Main overflow-auto auto-margin scroll-margin">
        <div className="Main-container auto-margin">
          
          <header className="build-info-container"> 
            <div className="build-info">
              <i>I built this website using</i> <code>React</code> <i>and hosting it via</i> <code>AWS S3</code>, <code>Route 53</code>, <i>and</i> <code>Cloudfront</code>.  
            </div>
          </header>

          <section className="About-container">
            <div className="profile-container">
              <div className="profile-info-container">
                <div className="name mono-font">
                  Elias Cannesson
                </div>

                <div className="about-metadata">
                  <div className="social-icon-container"> 
                    <a className="social-icon-link" href="https://www.github.com/elias-cannesson" target="_blank">
                      <LogoGithub height="100%" width="100%" />
                    </a>
                    <a className="social-icon-link" href="https://www.linkedin.com/in/elias-cannesson/" target="_blank">
                      <LogoLinkedin height="100%" width="100%" />
                    </a>
                    <a className="social-icon-link" href="mailto:elias.s.cannesson@gmail.com" target="_blank">
                      <Mail height="100%" width="100%" />
                    </a>
                  </div>
                  <div className="location content-font">
                    Located in Albany, CA
                  </div>
                </div>
              </div>
              <img src={profileImage} alt="Profile" className="profile-image" />

            </div>

            <div className="about-text-container">
              <div className="about-text-wrapper">
                <p className="about-title mono-font">
                  About Me
                </p>
                <p className="about-text content-font">
                  I am a fullstack engineer with a Bachelors in Computer Science and Math and 
                  over a year and a half experience of working in a startup engineering team, with 
                  expertise in MySQL, AWS, and Python. I am passionate about studying advanced algorithms, particularly approximation algorithms for 
                  polynomial complexity solutions to NP problems. I am looking to apply my problem 
                  solving skills in a theoretical and / or industry context.
                </p>
              </div>
            </div>
          </section>

          <section className="Skills-technologies-container">
            <div className="section-title">
               {/*<i>Work in progress...</i>*/} Skills / Technologies 
               <div className="skills-list">
                <span className="skill-item">Python</span>
                <span className="skill-item">JavaScript</span>
                <span className="skill-item">C++</span>
                <span className="skill-item">React</span>
                <span className="skill-item">Node.js</span>
                <span className="skill-item">AWS</span>
                <span className="skill-item">SQL</span>
                <span className="skill-item">NoSQL</span>
                <span className="skill-item">PHP</span>
                <span className="skill-item">GitHub</span>
                <span className="skill-item">Docker</span>
                <span className="skill-item">REST</span>
                <span className="skill-item">Machine Learning</span>
                <span className="skill-item">TensorFlow</span>
                <span className="skill-item">PyTorch</span>
                <span className="skill-item">Linux</span>
                <span className="skill-item">C</span>
                <span className="skill-item">Bash</span>
                <span className="skill-item">ElasticSearch</span>
                <span className="skill-item">Stripe</span>
                <span className="skill-item">Braintree</span>
                <span className="skill-item">GPU/Edge Computing</span>
                <span className="skill-item">Multiprocessing</span>
                <span className="skill-item">Multithreading</span>
                <span className="skill-item">Assembly</span>
               </div>
            </div>

          </section>

          <section className="Work-experience-container">
            <p className="section-title">
              Work / Research Experience
            </p>

            <div className="experience-text-wrapper">
              <div className="experience-header">
                <div className="experience-title">
                  <i>Backend Developer</i> - Sequoia Port
                </div>
                <div className="experience-year mono-font">
                  Feb 2022 - Aug 2023
                </div>
              </div>
              <div className="experience-text">
                - Developed a pivotal data pipeline using Python, AWS, SQL, NoSQL, and Stripe, 
                transmitting encrypted data for insurance and medical research clients. <br></br>
                - Contributed to securing a multi-million dollar NIH grant by showcasing 
                aggregated data for training preventive-care AI models. <br></br>
                - Maintained AWS-hosted SQL and NoSQL databases, optimizing performance and scalability. <br></br>
                - Led critical projects including a new payment system using Stripe, websockets functionality,
                  and backend transition to serverless architecture, significantly enhancing scalability. <br></br>
              </div>
            </div>

            <div className="experience-text-wrapper">
              <div className="experience-header">
                <div className="experience-title">
                  <i>Advanced Coders: AI Training </i> - Outlier AI
                </div>
                <div className="experience-year mono-font">
                  May 2024 - Present
                </div>
              </div>
              <div className="experience-text">
                - Assess quality of outputs and provide feedback to Large Language Models.
              </div>
            </div>

            <div className="experience-text-wrapper">
              <div className="experience-header">
                <div className="experience-title">
                  <i>Americorps Math Fellow </i> - Blueprint Schools Network
                </div>
                <div className="experience-year mono-font">
                  Mar 2024 - May 2024
                </div>
              </div>
              <div className="experience-text">
                - Provided equitable math education alongside Math teachers and other Math fellows in Oakland Unified School
                District at Roosevelt Middle School.
              </div>
            </div>

            <div className="experience-text-wrapper">
              <div className="experience-header">
                <div className="experience-title">
                  <i>Undergraduate Researcher </i> - CS/Math Department, <br></br>Oberlin College
                </div>
                <div className="experience-year mono-font">
                   May 2022 - Aug 2022
                </div>
              </div>
              <div className="experience-text">
                - Worked on proving useful theoretical lemmas alongside my advisor for algorithmic analyses of
                systems pertaining to algorithmic pricing, online learning, mechanism design, and repeated auctions.
              </div>
            </div>

            <div className="experience-text-wrapper">
              <div className="experience-header">
                <div className="experience-title">
                  <i>Data Structures Lab Tutor </i> - CS Department, <br></br>Oberlin College
                </div>
                <div className="experience-year mono-font">
                  Sept 2021 - Jan 2022
                </div>
              </div>
              <div className="experience-text">
                - Tutored 50+ students in Object Oriented Programming with Python and Data Structures with Java. 
                Instructed students in individual and small group settings for their lab assignments. 
                Helped students to understand data structures concepts and advised them on ways to write better code.
              </div>
            </div>

            <div className="experience-text-wrapper">
              <div className="experience-header">
                <div className="experience-title">
                  <i>Product Manager </i> - SELAH Neighborhood Homeless Coalition
                </div>
                <div className="experience-year mono-font">
                   Jan 2022 - May 2022
                </div>
              </div>
              <div className="experience-text">
                - Revamped the case-management database and UX of Apricot Solutions-hosted platform for volunteers.
                Responsible for the authentication of new users to the platform and troubleshooting technical issues.
              </div>
            </div>
          </section>

          <section className="Education-container">
            <div className="section-title">
              Education
            </div>

            <div className="education-text-wrapper">
              <div className="education-header">
                <div className="education-title">
                  <i>Oberlin College, Oberlin, OH </i>
                </div>
                <div className="education-year mono-font">
                  Graduated December 2022
                </div>
              </div>
              <div className="education-text">
                <i> Bachelors of Arts,</i> <u>Fields of study:</u> CS, Mathematics, Politics
                <br></br>
                <i>Student Athlete,</i> Cross Country/Track and Field NCAA III
                <br></br>
                
                <div className="education-text-coursework"><u>Coursework:</u> Data Structures, Discrete Mathematics, Algorithms, Advanced Algorithms, 
                Systems Programming, Linear Algebra, Abstract Algebra, Probability Theory, Machine Learning,
                Theory of Computation
                </div>
              </div>
            </div>
          </section>
          <section className="Projects-container"> 
            <div className="section-title">
              Projects
            </div>
            <div className="project-wrapper">
              <div className="project-header"> 
                <div className="project-title">
                  <i>Personal Website</i> - 
                </div>
                <a className="project-link" href="https://github.com/elias-cannesson/ResumeWebsite" target="_blank" rel="noopener noreferrer">
                  <LinkOutline color="lightblue"height="2rem" width="2rem" />
                </a>
                <div className="project-year mono-font"> May 2024 </div>
              </div>
              <div className="project-description">
              - Built with React, hosted by AWS S3, Cloudfront, and Route 53. Automated deployment using AWS Codebuild and Codecommit. 
              </div>
            </div>
            <div className="project-wrapper">
              <div className="project-header"> 
                <div className="project-title">
                  <i>Deep Q Network Snake AI</i> - 
                </div>
                <a className="project-link" href="https://github.com/elias-cannesson/aml-enjoyers" target="_blank" rel="noopener noreferrer">
                  <LinkOutline color="lightblue"height="2rem" width="2rem" />
                </a>
                <div className="project-year mono-font"> Dec 2022 - Mar 2023 </div>
              </div>
              <div className="project-description">
              - This project combines neural networks and Q-learning to train an agent using Deep Q Networks (DQN)
                 to play a two-dimensional snake game. Developed in Python with pygame, the snake learns to play 
                 autonomously by learning from past experiences.
              </div>
            </div>
            <div className="project-wrapper">
              <div className="project-header"> 
                <div className="project-title">
                  <i>Chess Engine</i> - 
                </div>
                <a className="project-link" href="https://github.com/elias-cannesson/chessAI" target="_blank" rel="noopener noreferrer">
                  <LinkOutline color="lightblue"height="2rem" width="2rem" />
                </a>
                <div className="project-year mono-font"> June 2021 - Aug 2021 </div>
              </div>
              <div className="project-description">
              - Chess Engine I wrote in C after learning the language on my own time. 
              I coded this mostly over the summer of 2021. 
              It can give me a run for my money in certain games, I would give it an ELO of 1700-1800. 
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
}

export default App;
